import {addDays, differenceInDays, parseISO, startOfToday, subDays} from "date-fns";
import {router} from "@inertiajs/vue3";
import {getActiveLanguage, trans} from "laravel-vue-i18n";
import {route} from "ziggy-js";

export const trField = (obj, field) =>
    obj[field + (getActiveLanguage() === "et" ? "" : "_ru")] ?? null;

export const __ = (key, replacements = {}) => trans(key, replacements);

export const calcVat = (vat) => Math.round((vat - 1) * 100);

export const hasDiscount = (
    price,
    price_default,
    discount_start,
    discount_end
) => {
    const discountEnd = addDays(
        discount_end ? parseISO(discount_end) : new Date(),
        1
    );
    const discountStart = discount_start ? parseISO(discount_start) : new Date();
    const today = startOfToday();
    const startDiff = differenceInDays(today, discountStart);
    const endDiff = differenceInDays(discountEnd, today);
    const hasStarted = discount_start ? startDiff >= 0 : true;
    const hasNotEnded = discount_end ? endDiff >= 0 : false;
    return price_default !== price && hasNotEnded && hasStarted ? endDiff : 0;
};

export const hasEnded = (end) => {
    if (!end) {
        return false;
    }
    const periodEnd = addDays(parseISO(end), 1);
    const today = startOfToday();
    const endDiff = differenceInDays(periodEnd, today);
    return end ? endDiff <= 0 : false;
};

export const isLoadbee = (brand) => {
    return (
        brand &&
        (brand.toLowerCase() === "miele" || brand.toLowerCase() === "bosch")
    );
};

export const isFlixmedia = (brand) => {
    return (
        brand &&
        (brand.toLowerCase() === "aeg"
            || brand.toLowerCase() === "beko"
            || brand.toLowerCase() === "electrolux"
            || brand.toLowerCase() === "samsung")
    );
};

export const isEstorecontent = (brand) => {
    return (
        brand &&
        (brand.toLowerCase() === "whirlpool" || brand.toLowerCase() === "indesit")
    );
};

export const carouselTranslations = {
    firstSlideMessage: __('See on esimene slaid'),
    lastSlideMessage: __('See on viimane slaid'),
    nextSlideMessage: __('Järgmine slaid'),
    paginationBulletMessage: __('Mine slaidile {{index}}'),
    prevSlideMessage: __('Eelmine slaid'),
};

export const carouselBreakpoints = {
    640: {
        slidesPerView: 1.5,
        centerInsufficientSlides: false
    },
    768: {
        slidesPerView: 2,
        centerInsufficientSlides: false
    },
    1024: {
        slidesPerView: 3,
        centerInsufficientSlides: false
    },
    1280: {
        slidesPerView: 4,
        centerInsufficientSlides: false
    }
};

export const shortBreakpoints = {
    640: {
        slidesPerView: 2,
        centerInsufficientSlides: false
    },
    768: {
        slidesPerView: 2.5,
        centerInsufficientSlides: false
    },
    1024: {
        slidesPerView: 2.5,
        centerInsufficientSlides: false
    },
    1280: {
        slidesPerView: 3,
        centerInsufficientSlides: false
    },
};

export const isEmail = (mail) =>
    mail.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    );

export const isCheckout = (route) => ["checkout", "checkout-ru"].includes(route);

export const oembed = (content) => {
    const regex =
        /<oembed.+?url="https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})"><\/oembed>/g;
    return (
        content &&
        content.replace(
            regex,
            "<div style=\"position: relative; height: 0; padding-bottom: 56.2493%;\">" +
            "<iframe src=\"https://www.youtube.com/embed/$1\" " +
            "style=\"border: 0; position: absolute; width: 100%; height: 100%; top: 0; left: 0;\" " +
            "allow=\"autoplay; encrypted-media\" allowfullscreen>" +
            "</iframe>" +
            "</div>"
        )
    );
};

export const bannerUrl = (
    filename,
    width = null,
    height = null,
    changeType = null
) => {
    let fallback = "/images/no-image.png";
    if (changeType) {
        const ext = filename ? filename.split(".").pop() : "";
        filename = ext ? filename.replace("." + ext, "." + changeType) : "";
        fallback = "/images/no-image.webp";
    }
    height = height ? height : width;
    let dims = "";
    if (width) {
        dims = "/" + width + "x" + height;
    }
    return (
        import.meta.env.VITE_BANNERS_URL +
        (filename
            ? "/storage/" + filename.substring(0, 1) + dims + "/" + filename
            : fallback)
    );
};

export const imageUrl = (
    filename,
    width = null,
    height = null,
    changeType = null
) => {
    let fallback = "/images/no-image.png";
    if (changeType) {
        const ext = filename ? filename.split(".").pop() : "";
        filename = ext ? filename.replace("." + ext, "." + changeType) : "";
        fallback = "/images/no-image.webp";
    }
    height = height ? height : width;
    let dims = "";
    if (width) {
        dims = "/" + width + "x" + height;
    }
    return (
        import.meta.env.VITE_IMAGES_URL +
        (filename
            ? "/storage/" + filename.substring(0, 1) + dims + "/" + filename
            : fallback)
    );
};

export const useRoute = (name = null, params = null, absolute = true) => route(name, params, absolute);

export const trRoute = (name, params = {}, absolute = true) =>
    useRoute(name + (getActiveLanguage() === "et" ? "-et" : "-ru"), params, absolute);

export const range = (start, stop, step = 1) =>
    Array(Math.ceil((stop - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);

export const loanDownPayments = (amount) => {
    let downPayments = [];
    const options = range(0, 90, 5);
    for (let i = 0; i < options.length; i++) {
        let amt = Math.ceil((options[i] / 100) * amount);
        if (amount - amt >= import.meta.env.VITE_ESTO_MIN) {
            downPayments.push({amount: amt, percent: options[i]});
        }
    }

    return downPayments;
};

export const loanMonths = () => [4, 6, 9, 12, 18, 24, 36, 48];

export const installment = (price) =>
    price >= parseInt(import.meta.env.VITE_ESTO_MIN) &&
    price <= parseInt(import.meta.env.VITE_ESTO_MAX);

export const orderStatuses = {
    closed: __("Kinnitatud"),
    failed: __("Makse ebaõnnestunud"),
    invoice: __("Maksan hiljem"),
    open: __("Pooleli")
};

export const orderStatus = (status) => orderStatuses[status];

export const isNew = (created_at) =>
    created_at &&
    differenceInDays(parseISO(created_at), subDays(new Date(), 60)) > 0;

export const loan = (amount, months = 48, downPayment = 0, precise = false) => {
    const sum =
        ((22 / 12 / 100) * (amount - downPayment)) /
        (1 - (1 + 22 / 12 / 100) ** -months);
    return precise ? price(sum) : Math.ceil(sum) + " €";
};

export const price = (value) => {
    return isNaN(value)
        ? value
        : new Intl.NumberFormat("et-EE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
};

export const priceFraction = (price, main) => {
    const precision = Math.round(price * 100) !== Math.round(price) * 100 ? 2 : 0;
    const intPart = Math.floor(price);
    const fraction = price - intPart;
    if (main) {
        return intPart;
    } else {
        const frac = Math.round(fraction * 100);
        return (
            (precision === 0 ? "" : "," + (frac < 10 ? "0" + frac : frac))
        );
    }
};

export const url = (url, params = false) => {
    let query = "";
    const esc = encodeURIComponent;
    if (params) {
        query = Object.keys(params)
            .map((k) => esc(k) + "=" + esc(params[k]))
            .join("&");
    }
    return (
        "/" +
        (getActiveLanguage() === "et" ? "" : "ru/") +
        url +
        (query ? "?" + query : "")
    );
};

export const paymentMethod = (method) => {
    const fallback = "-";
    const methods = {
        ep_citadele: "Citadele",
        ep_cc: "krediitkaart",
        ep_lhv: "LHV",
        ep_luminor: "Luminor",
        ep_seb: "SEB",
        ep_swed: "Swedbank",
        estcard: "krediitkaart",
        lhv: "LHV",
        invoice: "arvega",
        seb: "SEB",
        coop: "Coop Pank",
        swed: "Swedbank",
        test: "Testpank",
        sebtest: "Testpank",
        esto3: "ESTO 3",
        esto: "ESTO järelmaks",
        inbank: "Inbank järelmaks",
        indivy_slice: "Indivy SLICE",
        indivy_plan: "Indivy PLAN",
        lhv_cof: 'LHV järelmaks',
        lhv_cof_test: 'LHV järelmaks TEST'
    };
    return methods[method] || fallback;
};

export const stripTags = (str) => str.replace(/(<([^>]+)>)/gi, "");

export const values = (obj) => {
    let vals = [];
    if (obj) {
        vals = Object.keys(obj).map((key) => {
            return obj[key];
        });
    }
    return vals;
};

export const slash = (url) => url ? (url.indexOf("/") !== 0 ? "/" + url : url) : "";

export const toCart = (params, settings = null) => {
    const TAGS_ENABLED = import.meta.env.VITE_TAGS_ENABLED;
    TAGS_ENABLED && typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
        event: "add_to_cart",
        send_to: "AW-871690851/pcWmCN-Xv7wDEOPk058D"
    });
    router.post(trRoute("cart"), params, settings);
}
